import { Box, Button, IconButton, Image, Input } from "@chakra-ui/react";
import endpoints from "../../../utils/endpoints";
import { IoMdClose } from "react-icons/io";
import { publicAxios as axios } from "../../../config/axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import services from "../../Orders/services";
import useNotification from "../../../utils/useNotification";
import { FaExternalLinkAlt } from "react-icons/fa";
import { GoAlert } from "react-icons/go";

import useSound from "use-sound";
import cart from "./sounds/cart.mp3";
import puzzleIcon from "./puzzle.png";
import constants from "../../../utils/constants";

const CarInternalIframe = () => {
  const [playSound] = useSound(cart);
  const [displayBox, setDisplayBox] = useState("none");
  const toast = useNotification();
  const [activePartIndex, setActivePartIndex] = useState(-1);
  const [partsData, setPartsData] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedCarData = state?.carData;

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  useEffect(() => {
    if (displayBox === "block") {
      document.getElementById("part-qty")?.focus();
    }
  }, [displayBox]);
  const handleMessage = async (event: any) => {
    if (event.data.type === "polyHotspotClicked") {
      handlePolyHotspotClicked(event.data.hotspotInfo);
      return;
    }
  };
  async function handlePolyHotspotClicked(hotspotInfo: any) {
    setPartsData((prevPartsData) => {
      const partIndex = prevPartsData.findIndex(
        (part) => part.UPN === hotspotInfo.title
      );
      if (partIndex === -1) {
        fetchCarPartDetailsAndUpdate(hotspotInfo.title, prevPartsData);
      } else {
        setActivePartIndex(partIndex);
        setDisplayBox("block");
      }
      return prevPartsData;
    });
  }
  const fetchCarPartDetailsAndUpdate = async (
    partCategory: any,
    prevPartsData: any[]
  ) => {
    const carPartDetails = await fetchCarPartDetails(partCategory);
    setPartsData([
      ...prevPartsData,
      { ...carPartDetails, quantity: 1, o2vrUPN: partCategory },
    ]);
    setActivePartIndex(prevPartsData.length);
    setDisplayBox("block");
  };
  async function fetchCarPartDetails(partCategory: any) {
    try {
      const response = await axios({
        url: `${endpoints.getCarPartDetails(
          selectedCarData._id
        )}?partCategory=${partCategory}`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });

      return response.data;
    } catch (err) {}
  }

  async function handleAddToCart(
    legacyPartMasterId: any,
    orderedQuantity: any
  ) {
    try {
      if (!state.orderId || !state.orderNo) {
        toast({
          status: "error",
          description: "Please select an order",
          duration: 2000,
        });
        return;
      }
      const updatedOrder = await services.insertOrUpdatePart(state.orderId, {
        partId: legacyPartMasterId,
        orderedQuantity: orderedQuantity,
      });
      setDisplayBox("none");
      toast({
        status: "success",
        description: `Successfully added part to order : ${state.orderNo}`,
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        description: `${error}`,
        duration: 2000,
      });
    }
  }

  async function handleMissingPartInformation() {
    try {
      const response = await axios({
        method: constants.POST,
        url: `${endpoints.missingCarPartInformation(
          selectedCarData._id
        )}?o2vrUPN=${partsData[activePartIndex].o2vrUPN}`,
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });
      toast({
        status: "success",
        description: "Missing part information report submitted successfully",
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        description: "Something went wrong, please try again",
        duration: 2000,
      });
    }
  }

  return (
    <Box height={"100%"}>
      <iframe
        src={`/pano-output/index.html`}
        width={"100%"}
        height={"100%"}
        style={{ margin: "auto" }}
      ></iframe>

      <div
        style={{
          position: "absolute",
          left: "40%",
          top: "36%",
          width: "350px",
          height: "fit-content",
          backgroundColor: "#E6E5DB",
          display: displayBox,
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "#BFBFBF",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 style={{ margin: 0, color: "#414141" }}>Part Information</h3>
            <IconButton
              size={"xs"}
              bg={"none"}
              aria-label={""}
              ml={2}
              onClick={async () => {
                if (!partsData[activePartIndex]._id)
                  await handleMissingPartInformation();
              }}
              as={GoAlert}
              color={"white"}
              bgColor={"#F3565D"}
              p={1}
              cursor={"pointer"}
              _disabled={{
                bgColor: "lightgrey",
                cursor: "not-allowed",
                _hover: { backgroundColor: "lightgrey", shadow: "none" },
              }}
              isDisabled={partsData[activePartIndex]?._id ? true : false}
            ></IconButton>
          </div>

          <IconButton
            as={IoMdClose}
            aria-label=""
            size={"xs"}
            cursor={"pointer"}
            onClick={() => {
              setDisplayBox("none");
            }}
            color={"white"}
            bgColor={"#F3565D"}
          ></IconButton>
        </div>
        <div
          style={{
            padding: "5px",
            paddingLeft: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          UPN{" "}
          <span style={{ display: "inline-block", width: "50%" }}>
            {partsData[activePartIndex]?.UPN}
          </span>
        </div>
        <div
          style={{
            padding: "5px",
            paddingLeft: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Part Number
          <span style={{ display: "inline-block", width: "50%" }}>
            {partsData[activePartIndex]?.number}
          </span>
        </div>

        <div
          style={{
            padding: "5px",
            paddingLeft: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          MRP{" "}
          <span style={{ display: "inline-block", width: "50%" }}>
            {partsData[activePartIndex]?.mrp}
          </span>
        </div>
        <Box
          padding={"10px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div>
            <span> Qty : </span>
            <input
              id="part-qty"
              type="text"
              style={{ width: "50px", paddingLeft: "6px" }}
              value={partsData[activePartIndex]?.quantity ?? 1}
              onChange={(e) => {
                if (isNaN(+e.target.value)) return;
                let newPartsData = partsData.map((item, index) => {
                  if (index === activePartIndex) {
                    return {
                      ...item,
                      quantity: +e.target.value,
                    };
                  } else {
                    return item;
                  }
                });
                setPartsData(newPartsData);
              }}
            ></input>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              mr={1}
              bg={"none"}
              color={"black"}
              size={"xs"}
              icon={<FaExternalLinkAlt></FaExternalLinkAlt>}
              aria-label={"link"}
              justifySelf={"flex-end"}
              onClick={() => {
                window.open(partsData[activePartIndex].partLink, "_blank");
              }}
              _focus={{ bg: "none", boxShadow: "none" }}
            ></IconButton>

            <Button
              style={{}}
              bg={"none"}
              _hover={{
                bg: "none",
                boxShadow: "none",
              }}
              color={"black"}
              cursor={"pointer"}
              display={"flex"}
              justifySelf={"flex-end"}
              alignItems={"center"}
              height={"fit-content"}
              p={0}
              _focus={{ bg: "none", boxShadow: "none" }}
            >
              {" "}
              +{" "}
              <Image
                width="20px"
                height="20px"
                src="https://img.icons8.com/material-rounded/24/shopping-cart.png"
                alt="shopping-cart"
                onClick={() => {
                  handleAddToCart(
                    partsData[activePartIndex]._id,
                    partsData[activePartIndex].quantity
                  );
                  playSound();
                }}
              />
            </Button>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default CarInternalIframe;
